import React from "react"
import Container from "../styles/Container";
import styled from "styled-components";
import Image from 'gatsby-image/withIEPolyfill';


const HeroHeadingWrapper = styled.div`

    position: relative;
    @media (min-width: ${props => props.theme.screenMd}) {
      max-width: 50vw;
    }
    @media (min-width: ${props => props.theme.screenLg}) {
      max-width: 40rem;
    }
  `;

const HeroHeading = styled.h1`
    color: #fff;
    font-size: ${props => props.theme.fontLg};
    @media (min-width: ${props => props.theme.screenLg}) {
      font-size: ${props => props.theme.fontXl};
    }
  `;

const HeroWrapper = styled.div`
    position: relative;

  `;

const HeroContent = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
`;

const HeroContainer = styled(Container)`
  position: relative;
  height: 100%;
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
   padding-top: 2rem;
  @media (min-width: ${props => props.theme.screenLg}) {
    padding-top: 4rem;
    justify-content: space-between;
  }
  @media (max-width: ${props => props.theme.screenMdLgMax}) {
    flex-direction: column;
  }
`;

const HeroText = styled.div`
  color: #fff;

  a {
    color: #fff;
  }
  font-size: ${props => props.theme.fontSmall};
   @media (min-width: ${props => props.theme.screenLg}) {
     font-size: ${props => props.theme.fontMd2};
   }

`;

const ImageWrapper = styled(Image)`
  height: 50rem;
  @media (min-width: ${props => props.theme.screenLg}) {
    height: 40rem;
  }
`;

class Hero extends React.Component {

  render() {

    return (
      <HeroWrapper>
        <ImageWrapper fluid={this.props.sources}/>
        <HeroContent>
          <HeroContainer>
            <HeroHeadingWrapper>
              <HeroHeading>Curriculum Management Software Comparison — Compare Curriculog, Smart Catalog, and Clean
                Catalog</HeroHeading>

              <HeroText>

                <p>If you're shopping for curriculum management software for your college or university, these are
                  probably the
                  options you're looking into. Here's a breakdown of the features they offer.</p>

                <p>Disclaimer: This page is brought to you by <a href="https://cleancatalog.com">Clean Catalog</a>, so
                  it <em>is</em> biased —&nbsp;but we still think it does a good job of laying out the strengths and
                  weaknesses
                  of
                  curriculum management software providers.</p>

                <p><a href="https://cleancatalog.com/contact" className="btn">Contact Clean Catalog</a></p>


                <p><a href="https://cleancatalog.com/case-studies/coastalpines-smart-catalog">Read our case study on
                  switching
                  from Smart Catalog to Clean Catalog.</a></p>

                <p><a href="https://coursecatalogsoftware.com/">View our Course Catalog Software Comparison</a></p>
              </HeroText>
            </HeroHeadingWrapper>
          </HeroContainer>
        </HeroContent>
      </HeroWrapper>
    )
  }
}

export default Hero
