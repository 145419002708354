import React from "react"

import { ThemeProvider } from "styled-components";
import Theme from "../styles/Theme";
import { createGlobalStyle } from "styled-components"

const GlobalStyle = createGlobalStyle`
  h1 {
    font-size: ${props => props.theme.fontXl};
  }

  h2 {
    font-size: ${props => props.theme.fontLg};
  }

  h3 {
    font-size: ${props => props.theme.fontMd};
  }

  h4 {
    font-size: ${props => props.theme.fontMd2};
  }


  a {
    color: #000000;
  }


`;

class Layout extends React.Component {
  render() {
    const { children } = this.props;

    return (
      <div id={'page-wrapper'}>
      <ThemeProvider theme={Theme} >
        <GlobalStyle />
        {/*<Header />*/}
        <main >
          {children}
          </main>
        {/*<Footer />*/}

      </ThemeProvider>
      </div>
    )
  }
}

export default Layout
