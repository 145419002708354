import React from "react"

import Layout from "../components/layout"
import SEO from "../components/seo";
import Container from "../styles/Container";
import styled from "styled-components";
import {graphql, useStaticQuery} from "gatsby";
import Image from 'gatsby-image/withIEPolyfill';
import Hero from "../components/hero";
import Footer from "../components/footer";


const StyledHeader = styled.header`
  background-color: #beddf4;
  img {
    margin: 0;
  }
`;

const StyledTable = styled.table`
  @media(max-width: ${props => props.theme.screenMdMax}) {
    font-size: 10px;
  }
  td,
  th {
    line-height: 1.1;
  }

`;

const BlueWrapper = styled.div`
  padding-top: 40px;
  padding-bottom: 40px;
  background-color: #beddf4;
`;


const PricingRow = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
   @media(max-width: ${props => props.theme.screenMdMax}) {
     flex-direction: column;
   }
`;


const RowPanel = styled.div`
  width: 30%;
  display: flex;
  flex-direction: column;
    @media(max-width: ${props => props.theme.screenMdMax}) {
    width: 100%;
    margin-bottom: 30px;
  }
  h3 {
    background: #535353;
    padding: 10px;
    color: #fff;
    margin: 0;
  }
  div {
    background: #797979;
    color: #fff;
    padding: 10px;
    height: 100%;
  }
  ul {
    margin-bottom: 0;
  }

`;


export default () => {


  const data = useStaticQuery(graphql`
    query {
      logo: file(
        absolutePath: { regex: "/clean-catalog_brand-assets_stacked-bw.png/" }
      ) {
        childImageSharp {
          fixed(width: 200, quality: 100) {
            ...GatsbyImageSharpFixed_withWebp_tracedSVG
          }
        }
      }
      tinyImage: file(
        absolutePath: { regex: "/hero.jpg/" }
      ) {
        childImageSharp {
          fluid(maxWidth: 480, quality: 100) {
            ...GatsbyImageSharpFluid_withWebp_tracedSVG
          }
        }
      }
      largeImage: file(
        absolutePath: { regex: "/hero.jpg/" }
      ) {
        childImageSharp {
          fluid(maxWidth: 1920, quality: 100) {
            ...GatsbyImageSharpFluid_withWebp_tracedSVG
          }
        }
      }

    }
  `);


  const sources = [
    data.tinyImage.childImageSharp.fluid,

    {
      ...data.largeImage.childImageSharp.fluid,
      media: `(min-width: 992px)`,
    },

  ];


  return (
    <Layout>
      <SEO
        title={'Curriculum Management Software — Curriculog, Smart Catalog'}
        description={'Compare pricing, features, and more for Curriculog, Smart Catalog, and Clean Catalog curriculum management software.'}
      />


      <StyledHeader>
        <Container>
          <a href={"https://cleancatalog.com"}>
            <Image fixed={data.logo.childImageSharp.fixed} alt="Clean Catalog logo"/>
          </a>
        </Container>


      </StyledHeader>


      <Hero
        sources={sources}
        title='Course catalog software, curriculum management software, and student handbook software — built for colleges and universities.'
        alt={'Person using curriculum management software on a laptop.'}
      />

      <Container>


      </Container>

      <Container>

        <StyledTable>
          <thead>
          <th></th>
            <th>Clean Catalog</th>
            <th>Curriculog</th>
            <th>Smart Catalog</th>
          </thead>
          <tbody>
          <tr>
            <td>Customizable Approval Workflows</td>
            <td>X</td>
            <td>X</td>
            <td>X</td>
          </tr>
          <tr>
            <td>Initial Workflow Buildout Done for You for No Additional Cost</td>
            <td>X</td>
            <td></td>
            <td></td>
          </tr>
          <tr>
            <td>API to Integrate with Campus Systems</td>
            <td>X</td>
            <td>X</td>
            <td>X</td>
          </tr>
          <tr>
            <td>Seamless Integration with Course Catalog</td>
            <td>X</td>
            <td>X</td>
            <td>X</td>
          </tr>
          <tr>
            <td>Syllabus Management</td>
            <td>X</td>
            <td>X</td>
            <td>X</td>
          </tr>
          <tr>
            <td>Free Design and Tech Customizations</td>
            <td>X</td>
            <td></td>
            <td></td>
          </tr>

          <tr>
            <td><a href="https://cleancatalog.com/blog/curriculum-management-workflows">Workflows and Editorial Management</a></td>
            <td>X</td>
            <td>X</td>
            <td>X</td>
          </tr>
          <tr>
            <td>SSL/HTTPS Security by Default on All Software</td>
            <td></td>
            <td></td>
            <td>X</td>
          </tr>
          </tbody>


        </StyledTable>

      </Container>


      <BlueWrapper>
        <Container>

          <h2>Pricing Structure Comparison</h2>

          <p>Clean Catalog offers <em>free</em> migrations from Acalog and Smart Catalog, typically at a lower annual
            cost.</p>

          <p><strong>Exact pricing for each provider varies, but Clean Catalog curriculum management software typically has a significantly lower annual cost than
            Curriculog and Smart Catalog — and no startup fee.</strong></p>


          <PricingRow>
            <RowPanel>
              <h3>Curriculog</h3>
              <div>
              <ul>
                <li>Annual fee</li>
                <li>Startup fee</li>
                <li>Separate fees for catalog and curriculum management software</li>
              </ul>
              </div>
            </RowPanel>


            <RowPanel>
              <h3>Smart Catalog Curriculum Management</h3>
              <div>
              <ul>
                <li>Annual fee</li>
                <li>Startup fee</li>
              </ul>
              </div>
            </RowPanel>

            <RowPanel>
              <h3>Clean Catalog Curriculum Management Software</h3>
              <div>
              <ul>
                <li>Annual fee</li>
                <li>No startup fee</li>
                <li>One low fee for integrated catalog and curriculum management software</li>
                <li>Handbooks included for no additional cost</li>

              </ul>
              </div>
            </RowPanel>
          </PricingRow>

        </Container>
      </BlueWrapper>
      <Footer />


    </Layout>
  )

}


