const theme = {
  red: "#f96e64",
  green: "#51e59a",
  blue: "#4ae5d6",
  gray: '#dedee0',
  graydark: '#5d5d5d',
  fontXs: '13px',
  fontSmall: '16px',
  fontBase: '18px',
  fontMd2: '22px',
  fontMd: '26px',
  fontLg: '34px',
  fontXl: '42px',
  fontXxl: '58px',
  screenLg: '1300px',
  screenMdLg: '1100px',
  screenMd: '992px',
  screenSm: '768px',
  screenXs: '480px',
  screenLgMax: '1299px',
  screenMdLgMax: '1099px',
  screenMdMax: '991px',
  screenSmMax: '767px',
  screenXsMax: '479px',
  containerSm: '50rem',
  borderRadius: '10px'
};

export default theme;
