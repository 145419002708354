import React from "react"
import {Link, useStaticQuery, graphql} from "gatsby";
import styled from "styled-components";
import Container from "../styles/Container";
import Image from 'gatsby-image/withIEPolyfill';
import Newsletter from "./newsletter";
import FooterContact from "./footerContact";

const SiteFooter = styled.footer`
  background: ${props => props.theme.graydark};
  color: #fff;
  padding-top: 80px;
  padding-bottom: 40px;
  @media(max-width: ${props => props.theme.screenXsMax};) {
  background: black;
    padding-top: 20px;
    padding-bottom: 20px;
  }
`;

const SiteFooterContainer = styled(Container)`
  display: flex;
  justify-content: space-between;
  @media(max-width: ${props => props.theme.screenSmMax}) {
    flex-direction: column;
  }
`;

const SiteFooterRight = styled.nav`
  @media(max-width: ${props => props.theme.screenSmMax}) {
    order: 1;
  }
`;

const SiteFooterLeft = styled.div`
  @media (min-width: ${props => props.theme.screenSm}) {
    width: 50%;
    padding-right: 30px;
  }
   @media(max-width: ${props => props.theme.screenSmMax}) {
    order: 2;
  }
`;

const FooterLogo = styled(Link)`
  display: block;
  margin-bottom: 50px;
  margin-top: 50px;
  @media (min-width: ${props => props.theme.screenSm}) {
    margin-top: 270px;
    margin-bottom: 100px;
  }
  .gatsby-image-wrapper {
    max-width: 450px;
  }
`;


const SiteFooterMenu = styled.ul`
    margin: 0;
    padding: 0;
    list-style: none;
    li {
    a {
      color: #fff;
      text-transform: uppercase;
      padding-bottom: 20px;
      display: inline-block;
    }
  }
`;

const SiteFooterSocial = styled.div`
  @media(max-width: ${props => props.theme.screenXsMax};) {
    padding: 15px 0;
  }
  a {
    display: inline-flex;
    margin-right: 10px;
  }
  svg {
    width: 30px;
    height: 30px;
  }
`;

const FooterP = styled.p`
  margin-bottom: 0;
`;


export default () => {

  const data = useStaticQuery(graphql`
    query {
      zzz: file(absolutePath: { regex: "/zzz.png/" }) {
        childImageSharp {
          fixed(width: 50, height: 50) {
            ...GatsbyImageSharpFixed_withWebp_noBase64
          }
        }
      },
      twitter: file(absolutePath: { regex: "/twitter.png/" }) {
        childImageSharp {
          fixed(width: 50, height: 50) {
            ...GatsbyImageSharpFixed_withWebp_noBase64
          }
        }
      },
      facebook: file(absolutePath: { regex: "/facebook.png/" }) {
        childImageSharp {
          fixed(height: 50) {
            ...GatsbyImageSharpFixed_withWebp_noBase64
          }
        }
      },

      logo: file(absolutePath: { regex: "/logo-white.png/" }) {
        childImageSharp {
          fluid(maxWidth: 450) {
            ...GatsbyImageSharpFluid_withWebp_noBase64
          }
        }
      },
    }
  `)


  return (
    <div>
      <FooterContact/>


      <SiteFooter>

        <SiteFooterContainer>
          <SiteFooterLeft>
            <SiteFooterSocial>
              <Newsletter/>

              <a href="https://linkedin.com/company/clean-catalog" target="_blank" rel="noopener noreferrer">
                <Image
                  fixed={data.zzz.childImageSharp.fixed}
                  alt="Follow Clean Catalog on LinkedIn"
                />
              </a>
              <a href="https://twitter.com/CatalogClean" target="_blank" rel="noopener noreferrer">
                <Image
                  fixed={data.twitter.childImageSharp.fixed}
                  alt="Follow Clean Catalog on Twitter"
                />

              </a>

              <a href="https://www.facebook.com/cleancatalogsoftware/" target="_blank" rel="noopener noreferrer">
                <Image
                  fixed={data.facebook.childImageSharp.fixed}
                  alt="Follow Clean Catalog on Facebook"
                />
              </a>

            </SiteFooterSocial>
            <FooterLogo to={`/`}>
              <Image fluid={data.logo.childImageSharp.fluid} alt="Clean Catalog logo"/>
            </FooterLogo>
            <FooterP>© {new Date().getFullYear()} Clean Catalog</FooterP>
            <FooterP>Based in Seattle, WA</FooterP>
          </SiteFooterLeft>
          <SiteFooterRight>
            <SiteFooterMenu>
              <li>
                <a href="https://cleancatalog.com/start">
                  Get Started
                </a>
              </li>
              <li>
                <a href="https://cleancatalog.com/features">
                  Features & Technology
                </a>
              </li>
              <li>
                <a href="https://cleancatalog.com/blog">
                  Blog
                </a>
              </li>
              <li>
                <a href="https://cleancatalog.com/case-studies">
                  Case Studies
                </a>
              </li>
              <li><a href="https://cleancatalog.com/acalog">Free Migration from Acalog</a></li>
              <li><a href="https://cleancatalog.com/smart-catalog">Free Migration from Smart Catalog</a></li>
              <li><a href="https://employeehandbooksoftware.com">Employee Handbook Software</a></li>
              <li><a href="https://coursecatalogsoftware.com">Course Catalog Software Comparison</a></li>
              <li>
                <a href="https://cleancatalog.com/contact">
                  Contact
                </a>
              </li>
            </SiteFooterMenu>
          </SiteFooterRight>
        </SiteFooterContainer>
      </SiteFooter>
    </div>
  )
};
