/**
 * SEO component that queries for data with
 *  Gatsby's useStaticQuery React hook
 *
 * See: https://www.gatsbyjs.org/docs/use-static-query/
 */

import React from "react"
import PropTypes from "prop-types"
import Helmet from "react-helmet"
import { useStaticQuery, graphql } from "gatsby"

function SEO({ description, lang, meta, title }) {
  const { site } = useStaticQuery(
    graphql`
      query {
        site {
          siteMetadata {
            title
            description
          }
        }
      }
    `
  )

  const metaDescription = description || site.siteMetadata.description

  return (
    <Helmet
      htmlAttributes={{
        lang,
      }}
      title={'Curriculum Management Software — Curriculog, Smart Catalog'}

      titleTemplate={`%s`}
    >
      <meta name="description" content={metaDescription} />
      <meta name="twitter:description" content={metaDescription} />
      <meta name="og:type" content={metaDescription} />
      <meta name="twitter:title" content={title} />
      <meta name="og:title" content={title} />
      {/*<meta name="google-site-verification" content="axKKYDBfYK2jjP_JKYx3NGOSnP0j6ZnETzkK6h9-90U" />*/}
    </Helmet>
  )
}

SEO.defaultProps = {
  lang: `en`,
  meta: [],
  description: ``,
}

SEO.propTypes = {
  description: PropTypes.string,
  lang: PropTypes.string,
  meta: PropTypes.arrayOf(PropTypes.object),
  title: PropTypes.string.isRequired,
}

export default SEO
