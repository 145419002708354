import React from "react"

import Contact from "./contact";
import styled from "styled-components";
import Container from "../styles/Container";

const FormWrapper = styled.div`
  padding-top: 50px;
  `;

const Row = styled.div`
  display: flex;
  flex-wrap: wrap;
  width: 100%;
`;

const RowLeft = styled.div`
  width: 50%;
  @media(max-width: ${props => props.theme.screenMdMax}) {
    width: 100%;
    flex: 1 1 auto;
  }
`

const RowRight = styled.div`
  width: 50%;
  @media(max-width: ${props => props.theme.screenMdMax}) {
    width: 100%;
    flex: 1 1 auto;
  }
  .btn {
    background: ${props => props.theme.red};
  //color: #000;
    border: 2px solid ${props => props.theme.red};
    &:hover,
    &:focus {
      background: transparent;
        color: #000;
      }
  }

`;

class FooterContact extends React.Component {
  render() {

    return (
      <FormWrapper>
        <Container>
          <Row>
            <RowLeft>
              <h2>Want to learn more?</h2>
              <p>Contact us to schedule a demo, ask a question, or find out more about pricing.</p>
              <p>You can also email us at <a href="mailto:info@cleancatalog.com">info@cleancatalog.com.</a>
              </p>
            </RowLeft>
            <RowRight>
            <Contact/>
            </RowRight>
          </Row>
        </Container>
      </FormWrapper>
    )
  }
}

export default FooterContact
