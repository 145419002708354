import React from "react"
import styled from "styled-components";
import Loader from "react-loader";

const ContactWrapper = styled.form`
    display: flex;
    flex-direction: column;
    > div {
      display: flex;
      span {
        clip: rect(1px, 1px, 1px, 1px);
        clip-path: inset(50%);
        height: 1px;
        width: 1px;
        margin: -1px;
        overflow: hidden;
        padding: 0;
        position: absolute;
      }
    }
  `;


const StyledLabel = styled.label`
  text-transform: uppercase;
  font-weight: 700;
  font-size: ${props => props.theme.fontSmall};
  display: flex;
  flex-direction: column;
  line-height: 1.2;
`;

const StyledInput = styled.input`
  border: solid 1px ${props => props.theme.green};
  padding: 10px 20px;
  font-weight: 100;
`;



const StyledButton = styled.input`
  display: inline-block;
  background: ${props => props.theme.green};
  color: #000;
  border: solid 1px transparent;
  &:hover,
  &:focus {
    cursor: pointer;
    background: transparent;
    color: ${props => props.theme.green};
    border-color: ${props => props.theme.green};
  }
`;


const LoaderWrapper = styled.div`
  position: relative;
  height: 90px;
`;

const ResponseText = styled.span`
  font-size: ${props => props.theme.fontMd};
  max-width: 100%;
`;


class Newsletter extends React.Component {

  constructor(props) {
    super(props);
    this.state = {
      email: '',
      responsemessage: '',
      submitting: null,
    };

    this.handleSubmit = this.handleSubmit.bind(this);
    this.handleEmail = this.handleEmail.bind(this);
  }


  handleEmail(event) {
    this.setState({'email': event.target.value});
  }


  handleSubmit(event) {
    event.preventDefault();

    this.setState({
      'submitting': true
    });


    // This is the format Hubspot needs for submissions
    let data = {
      "submittedAt": Math.floor(Date.now()),
      "pageUrl": document.URL,
      "fields": [
        {
          "name": "email",
          "value": this.state.email
        },

      ]
    };


    const hubspotUrl = 'https://api.hsforms.com/submissions/v3/integration/submit/7009511/60ffc962-b4e3-4706-9aea-4f5ff2d5ec78';


    fetch(hubspotUrl, {
      method: 'POST', // *GET, POST, PUT, DELETE, etc.
      mode: 'cors', // no-cors, *cors, same-origin
      cache: 'no-cache', // *default, no-cache, reload, force-cache, only-if-cached
      credentials: 'same-origin', // include, *same-origin, omit
      headers: {
        'Content-Type': 'application/json'
      },
      redirect: 'follow', // manual, *follow, error
      referrerPolicy: 'no-referrer', // no-referrer, *client
      body: JSON.stringify(data) // body data type must match "Content-Type" header
    })
      .then((response) => {
        return response.json();
      })
      .then((responseJson) => {
        this.setState({'submitting': false});
        if (responseJson.status === 'error') {
          if (responseJson.errors[0].errorType === 'INVALID_EMAIL') {
            this.setState({'responsemessage': 'Please enter a valid email address.'});
          } else {
            this.setState({'responsemessage': 'There was an error with your submission. Please try again, or email us at info@cleancatalog.com.'});
          }
        } else {
          this.setState({'responsemessage': 'Thanks! We\'ll send you our next monthly newsletter.'});
        }

      })
      .catch((error) => {
        this.setState({'submitting': false});
        this.setState({'responsemessage': 'There was an error with your submission. Please try again, or email us at info@cleancatalog.com.'});
      })
    ;


  }


  render() {
    let submitButton = <StyledButton type="submit" value="SUBMIT" className="btn"/>;
    if (this.state.submitting === true) {
      submitButton = (
        <LoaderWrapper>
          <Loader loaded={!this.state.submitting} color='#f96e64'/>
        </LoaderWrapper>
      )
    }
    return (
      <ContactWrapper onSubmit={this.handleSubmit}>
        <h2>Subscribe to Our Newsletter</h2>
        <div>
          <StyledLabel>
            <span>Email</span>
            <StyledInput type="text" name="_replyto" placeholder="Email" onChange={this.handleEmail}/>
          </StyledLabel>
          {submitButton}
        </div>


        <ResponseText>{this.state.responsemessage}</ResponseText>
      </ContactWrapper>
    )

  }
}

export default Newsletter
